import {timeParse, timeFormat} from 'd3-time-format';
import {timeMonth, timeDay} from 'd3-time';

/**
 * Specifies date format
 * https://github.com/d3/d3-time-format/blob/master/README.md#locale_format
 */
const defaultSpecifier = '%Y-%m-%d';

export const getDate = (date, specifier = defaultSpecifier) => {
    return timeParse(specifier)(date);
};

export const getFormatted = (date, specifier = defaultSpecifier) => {
    return timeFormat(specifier)(date);
};

export const getToday = (specifier = defaultSpecifier) => {
    return getFormatted(new Date(), specifier);
};

export const getYesterday = (specifier = defaultSpecifier) => {
    return getFormatted(timeDay.offset(new Date(), -1), specifier);
};

export const getTomorrow = (specifier = defaultSpecifier) => {
    return getFormatted(timeDay.offset(new Date(), +1), specifier);
};

export const getSevenDays = (specifier = defaultSpecifier) => {
    return getFormatted(timeDay.offset(new Date(), -8), specifier);
};

export const getThirtyDays = (specifier = defaultSpecifier) => {
    return getFormatted(timeDay.offset(new Date(), -31), specifier);
};

export const getNinetyDays = (specifier = defaultSpecifier) => {
    return getFormatted(timeDay.offset(new Date(), -91), specifier);
};

export const formatDate = (date) =>
    date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();

export const stripLeadingZero = (str) => {
    if (str.toString().slice(0, 1) === '0') {
        return str.slice(1);
    }

    return str;
};

export const formatDateFromString = (dateTime) => {
    if (!dateTime) return '';

    //const month = months[+stripLeadingZero(dateTime.slice(5, 7)) - 1];
    const month = +stripLeadingZero(dateTime.slice(5, 7));
    const day = stripLeadingZero(dateTime.slice(8, 10));
    const year = dateTime.slice(0, 4);
    return `${month}/${day}/${year}`;
};

export const timeFromMilitary = (time) => {
    if (!time) return '';

    const hour24 = time.slice(0, 2);
    const hour = hour24 > 12 ? hour24 - 12 : hour24;
    const minute = time.slice(2);
    const amPm = hour24 >= 12 ? 'pm' : 'am';

    return `${hour === '00' ? 12 : stripLeadingZero(hour)}:${minute}${amPm}`;
};

export const timeToMilitary = (time) => {
    if (!time) return '';

    const isAm = time.match(/(am)/gi);
    const isPm = time.match(/(pm)/gi);

    const numsOnly = time.match(/\d/g) ? time.match(/\d/g).join('') : '';

    let hour = numsOnly.slice(0, 1);
    let minute = numsOnly.slice(-2);

    if (numsOnly.length === 1) {
        hour = '0' + numsOnly;
        minute = '00';
    }

    if (numsOnly.length === 2) {
        hour = numsOnly;
        minute = '00';
    }

    /**
     * Add zero padding
     */
    if (numsOnly.length === 3) {
        hour = 0 + hour;
    }

    if (numsOnly.length === 4) {
        hour = numsOnly.slice(0, 2);
    }

    if (isAm) {
        if (hour === '12') {
            hour = '00';
        }
    }

    if (isPm) {
        if (hour !== '12' && parseInt(hour) <= 12) {
            hour = hour === '12' ? 12 : (12 + parseInt(hour)).toString();
        }
    }

    return hour + minute;
};

export const formatTimeFromString = (dateTime) => {
    if (!dateTime) return '';

    const arr = dateTime.split(' ');
    const time = arr.length > 0 ? arr[1] : arr[0];
    const hour24 = +time.split(':')[0];
    const hour = hour24 > 12 ? hour24 - 12 : hour24;
    const minute = time.split(':')[1];
    const second = time.split(':')[2];
    const amPm = hour24 >= 12 ? 'pm' : 'am';

    return `${hour === 0 ? 12 : hour}:${minute}:${second}${amPm}`;
};

export const formatTimezoneFromString = (dateTime) => {
    if (!dateTime) return '';
    const regex = /[a-zA-Z]*$/g;
    return dateTime.match(regex) && dateTime.match(regex)[0];
};

export const formatSortableDate = (date) => {
    if (!date) return 0;

    const numsOnly = date.replace(/(:|\s|-|[a-z])/gi, '');
    return +numsOnly;
};
