import {createSelector} from 'reselect';

import {lower, sortAscending, capitalCase, camelToCapital} from 'utils/helpers';

const getPermissions = (state) => state.permissions.entities;
const getCurrentUserId = (state) => state.users.currentUserId;
const getUsers = (state) => state.users.entities;

export const selectPermissions = createSelector(
    [getPermissions],
    (permissions) => permissions,
);

export const selectIterablePermissions = createSelector(
    [getPermissions],
    (permissions) => {
        const iterable = Object.keys(permissions).map(
            (permission) => permissions[permission],
        );

        return [
            ...iterable.sort((a, b) =>
                sortAscending(lower(a.category), lower(b.category)),
            ),
        ];
    },
);

export const selectPermissionsByCategory = createSelector(
    [selectIterablePermissions],
    (permissions) => {
        const keyed = permissions.reduce((acc, cur) => {
            if (acc[camelToCapital(cur.entity)]) {
                acc[camelToCapital(cur.entity)].permissions.push({
                    name: cur.name,
                    display: `${camelToCapital(cur.action)} ${camelToCapital(
                        cur.detail,
                    )} - ${camelToCapital(cur.scope)}`,
                });
            } else {
                acc[camelToCapital(cur.entity)] = {
                    permissions: [
                        {
                            name: cur.name,
                            display: `${camelToCapital(cur.action)} ${camelToCapital(
                                cur.detail,
                            )} - ${camelToCapital(cur.scope)}`,
                        },
                    ],
                };
            }

            return acc;
        }, {});

        const formatted = Object.keys(keyed).map((key) => {
            const permissions = keyed[key].permissions;
            return {
                category: key,
                permissions: [
                    ...permissions.sort((a, b) =>
                        sortAscending(lower(a.display), lower(b.display)),
                    ),
                ],
            };
        });

        const sorted = [
            ...formatted.sort((a, b) =>
                sortAscending(lower(a.category), lower(b.category)),
            ),
        ];

        return sorted;
    },
);

export const selectCurrentUserPermissions = createSelector(
    [getUsers, getCurrentUserId],
    (users, id) => {
        const user = users[id] || {};

        return user.permissions;
    },
);
