import {createSelector} from 'reselect';

import {
    lower,
    sortAscending,
    sortNum,
    getListingCount,
    getListingType,
    get,
} from 'utils/helpers';
import {formatSortableDate} from 'utils/dates';

const getAccounts = (state) => state.accounts.entities;
const getSortKey = (state) => state.accounts.sortKey;
const getAccountIdParam = (state, props) => get(props, 'match.params.accountId');
const getSearchQuery = (state) => state.accounts.searchQuery;
const getOwnAccountId = (state) => state.accounts.currentAccountId;

export const selectIterableAccounts = createSelector([getAccounts], (accounts) => [
    ...Object.keys(accounts).map((key) => accounts[key]),
]);

export const selectSortedAccounts = createSelector([selectIterableAccounts], (accounts) =>
    accounts.sort((a, b) =>
        sortAscending(lower(a.organization_name), lower(b.organization_name)),
    ),
);

export const selectAccountId = createSelector(
    [getAccountIdParam, getOwnAccountId],
    (accountId, ownAccountId) => (accountId ? accountId : ownAccountId),
);

export const selectCurrentAccountId = createSelector(
    [getOwnAccountId],
    (accountId) => accountId,
);

export const selectCurrentAccount = createSelector(
    [getAccounts, selectCurrentAccountId],
    (accounts, accountId) => {
        return accounts[accountId];
    },
);

export const selectAccountById = createSelector(
    [getAccounts, selectAccountId],
    (accounts, id) => accounts[id] || null,
);

/*
 * This selector is used to0 many places to easily remove HOC withRouter
 * You'd have to explicitly pass accountId param to ton of selectors
 */
export const selectAccountIdParam = createSelector(
    [getAccountIdParam],
    (accountId) => accountId,
);

export const selectAccountByIdParam = createSelector(
    [getAccounts, getAccountIdParam],
    (accounts, id) => accounts[id] || null,
);

export const selectAccountByIdNoWithRouter = createSelector(
    [getAccounts, (state, accountId) => accountId],
    (accounts, accountId) => {
        return accounts[accountId] ? accounts[accountId] : null;
    },
);
