import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';

import {selectAccountIdParam} from 'features/accounts/selectors';
import {
    lower,
    sortDescending,
    sortAscending,
    formatSortableDate,
    filterSearchResults,
} from 'utils/helpers';

const getCurrentUserId = (state) => state.users.currentUserId;
const getUserIds = (state) => state.users.ids;
const getUserEntities = (state) => state.users.entities;
const getSortKey = (state) => state.users.sortKey;
const getIsFetching = (state) => state.users.isFetching;
const getOwnUserId = (state) => state.users.currentUserId;
const getFormValues = (state) =>
    formValueSelector('filter-users')(
        state,
        'account_id',
        'first_name',
        'last_name',
        'email',
        'role_name',
        'enabled',
    );

export const selectIterableUsers = createSelector(
    [getUserEntities, getUserIds],
    (users, ids) => ids.map((id) => users[id]),
);

export const selectHasFetchedUsers = createSelector(
    [selectIterableUsers, getIsFetching],
    (users, isFetching) => !isFetching && users.length > 0,
);

export const selectCurrentUserId = createSelector([getCurrentUserId], (userId) => userId);

export const selectCurrentUser = createSelector(
    [getUserEntities, getCurrentUserId],
    (users, id) => users[id],
);

export const selectCurrentUserPermissions = createSelector([selectCurrentUser], (user) =>
    user ? user.permissions : [],
);

export const selectWriterUsers = createSelector([selectIterableUsers], (users) => {
    const filtered = users.filter((user) => {
        return (
            user.permissions &&
            user.permissions.includes('editorial-submitEditorial-assignedEditorials') &&
            !user.permissions.includes('editorial-assignEditorial-all')
        );
    });

    return filtered.sort((a, b) => {
        return sortAscending(a.first_name, b.first_name);
    });
});

export const selectEditorUsers = createSelector([selectIterableUsers], (users) => {
    return users.filter((user) => {
        return (
            user.permissions &&
            user.permissions.includes('editorialFeedback-edit-all-note') &&
            !user.permissions.includes('editorialFeedback-edit-all-assignee')
        );
    });
});

export const selectFilteredUsers = createSelector(
    [selectIterableUsers, selectAccountIdParam, getFormValues, getOwnUserId],
    (users, accountId, formValues, ownId) => {
        const otherUsers = users.filter((user) => user.user_id !== ownId);

        const filteredUsers = filterSearchResults(
            otherUsers,
            Object.assign(accountId ? {account_id: accountId} : {}, formValues),
        );

        return filteredUsers.sort((a, b) => {
            return sortAscending(a.first_name, b.first_name);
        });
    },
);

export const selectUserById = createSelector(
    [getUserEntities, (state, userId) => userId],
    (users, userId) => {
        if (!userId) return null;
        if (!users) return null;

        return users[userId];
    },
);

export const selectUsersByAccountId = createSelector(
    [selectIterableUsers, (state, user) => user],
    (users, user) => {
        if (users.length > 0 && user) {
            const accountId = user.account_id;
            return users.filter((user) => user.account_id === accountId);
        }

        return [];
    },
);

export const selectUserIdFromPath = (state, props) => {
    const path = props?.location?.pathname;
    if (path) {
        const match = path.match(/^\/users\/([a-zA-Z0-9]+)\//);
        if (match && match[1] !== undefined) {
            return match[1];
        }
    }
    return null;
};

export const selectUserFromPath = createSelector(
    [selectIterableUsers, selectUserIdFromPath],
    (users, userId) => {
        if (users.length > 0 && userId) {
            if (!userId) return null;
            if (!users) return null;
            const usersFiltered = users.filter((user) => user.user_id === userId);
            return usersFiltered[0] !== undefined ? usersFiltered[0] : null;
        }

        return null;
    },
);
