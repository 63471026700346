import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';

import {TIMEZONES} from 'core/constants';
import {formatSortableDate} from 'utils/dates';
import {
    isEmpty,
    sortAscending,
    lower,
    include,
    getListingType,
    omit,
    filterSearchResults,
} from 'utils/helpers';

const getAccounts = (state) => state.accounts.entities;
const getListings = (state) =>
    state.listings && state.listings.entities ? state.listings.entities : [];
const getOwnAccountId = (state) => state.accounts.currentAccountId;
const getListingIdParam = (state, props) => props?.match?.params?.listingId;

const getReports = (state) => state.dashboard.reports;
const getSearchResults = (state) => state.listings.searchResults;
const getSortKey = (state) => state.listings.sortKey;
const getIsFetching = (state) => state.listings.isFetching;
const getCurrentUser = (state) => state.users.entities[state.users.currentUserId];

const photoSelector = formValueSelector('edit-listing');
const getListingPhotos = (state) => photoSelector(state, 'photos');

const searchFormSelector = formValueSelector('search-listings');
const getSearchFormValues = (state) =>
    searchFormSelector(
        state,
        'listing_name',
        'type',
        'claimed',
        'address.street_address',
        'address.region',
        'address.city',
    );

export const selectIterableListings = createSelector([getListings], (listings) =>
    Object.keys(listings).map((key) => listings[key]),
);

export const selectSortedListings = createSelector(
    [selectIterableListings, getSortKey],
    (listings, sortKey) => {
        switch (sortKey) {
            case 'modified_at':
                return [
                    ...listings.sort((a, b) =>
                        sortAscending(
                            formatSortableDate(b[sortKey]),
                            formatSortableDate(a[sortKey]),
                        ),
                    ),
                ];
            case 'listing_name':
                return [
                    ...listings.sort((a, b) =>
                        sortAscending(lower(a[sortKey]), lower(b[sortKey])),
                    ),
                ];
            default:
                return listings;
        }
    },
);

export const selectFilteredListings = createSelector(
    [
        selectSortedListings,
        (state, accountId) => accountId,
        getAccounts,
        getSearchFormValues,
    ],
    (listings, accountId, accounts, formValues) => {
        const filtered = filterSearchResults(
            listings,
            Object.assign({}, accountId ? {account_id: accountId} : {}, formValues),
        );

        return filtered;
    },
);

export const selectListingById = createSelector(
    [getListings, (state, listingId) => listingId],
    (listings, listingId) => {
        return listings[listingId] ? listings[listingId] : null;
    },
);

export const selectTimezoneDisplay = (listing) => {
    if (!listing || !listing.time_zone) return '';

    const timezone = TIMEZONES.find((timezone) => timezone.name === listing.time_zone);

    return timezone ? timezone.display : '';
};

/*
 * This selector is used to0 many places to easily remove HOC withRouter
 * You'd have to explicitly pass listingId param to ton of selectors
 */
export const selectListingIdParam = createSelector(
    [getListingIdParam],
    (listingId) => listingId,
);
